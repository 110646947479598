.hero {
	position: relative;
	width: 100%;
	background-color: $theme-color-yellow;
	background-position: center;
	background-size: cover;
	padding-top: 5rem;
	padding-bottom: 5rem;
	color: $theme-color-text;
	overflow: hidden;

	@media all and (max-width: 768px) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	&__gradient {

		&:after {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background: linear-gradient(180deg, rgba(0, 0, 0, 25%) 0%, rgba(0, 0, 0, 70%) 85.42%);
		}
	}

	&__rounded {
		border-radius: 40px;
	}

	&__page {
		height: 350px;
	}

	&__product {
		height: 700px;

		@media all and (max-width: 768px) {
			height: 240px;
		}
		//max-height: 600px;
		//padding-top: 0;
		//padding-bottom: 46.875%;

		&:after {
			background: linear-gradient(180deg, rgba(0, 0, 0, 50%) 0%, rgba(0, 0, 0, 0%) 100%);
		}
	}

	&__account {
		min-height: 300px;
		padding-top: 10rem;
		background-color: $theme-color-secondary;
		border-radius: 20px;
		color: $theme-color-yellow;
	}

	.container {
		position: relative;
		z-index: 2;
	}
}

.hero-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	object-position: center;
}

.hero-title {
	font-size: 3.375rem;
	margin-bottom: 1.5rem;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 740px;

	strong {
		color: $theme-color-secondary;
	}

	@media all and (max-width: 1024px) {
		font-size: 2.5rem;
	}

	@media all and (max-width: 768px) {
		font-size: 2rem;
	}
}

.hero-subtitle {
	font-size: 1.25rem;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	max-width: 80%;

	@media all and (max-width: 768px) {
		font-size: 1rem;
	}
}

.hero-button {
	margin-top: 3rem;
}

.hero-account-code {
	font-size: 1.5rem;
	margin-bottom: 1em;
}

.hero-arrow {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	z-index: 2;
}

.hero-account-code-detail {
	padding: 1rem;
	background: white;
	width: 100%;
	border-radius: 10px;
	position: relative;
	max-width: 400px;
	min-width: 250px;
}

.hero-account-status-wrapper {
	position: absolute;
	display: flex;
	align-items: center;
	top: 0;
	left: 10px;
	padding-left: 15px;
	padding-right: 15px;
	margin-right: 1rem;
	overflow: hidden;
	transform: translateY(-100%);
	min-height: 30px;

	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: transparent;
		border-bottom-right-radius: 15px;
		height: 30px;
		width: 15px;
		box-shadow: 0 15px 0 0 #fff;
		z-index: 1;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: transparent;
		border-bottom-left-radius: 15px;
		height: 30px;
		width: 15px;
		box-shadow: 0 15px 0 0 #fff;
		z-index: 1;
	}
}

.hero-account-status {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	padding-left: 5px;
	padding-top: 5px;
	padding-right: 1rem;

	&__success {
		color: $green;

		.hero-account-status-circle {
			background-color: $green;
			background-image: url("../../images/icon-status-success.svg");
		}
	}

	&__danger {
		color: $theme-color-cyan;

		.hero-account-status-circle {
			background-color: $theme-color-cyan;
			background-image: url("../../images/icon-status-danger.svg");
		}
	}
}

.hero-account-status-circle {
	display: inline-flex;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-color: $theme-color-cyan;
	margin-right: 10px;
	flex-shrink: 0;
	align-self: flex-start;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% auto;
}

.hero-account-status-text {
	font-size: 1rem;
}
