.hp-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 2rem;
	place-items: center;

	@media all and (max-width: 620px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.hp-card-item {
	width: 100%;
	border: 5px solid #fff;
	padding: 2.5rem 1rem;
	transition: all .3s ease-in-out;
	border-radius: 20px;

	&:hover {
		z-index: 1;
		box-shadow: 0 10px 30px rgba(black, 20%);
	}
}

.hp-card-item-header {
	margin-bottom: 3.125rem;
}

.hp-card-item-icon {
	margin-bottom: 3.125rem;
}

.hp-card-item-heading {
	margin-bottom: 0;
	color: $theme-color-primary;
	font-size: 1.375rem;
}

.hp-card-item-text {
	font-size: 1.375rem;
	font-weight: 700;
	max-width: 375px;
	margin: 0 auto;
}
