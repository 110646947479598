.cta-banner {
	background-color: #f5f5f5;
	padding: 7.5rem 2rem;
	text-align: center;
	position: relative;
	overflow: hidden;

	@media all and (max-width: $breakpoint-tablet) {
		padding: 5rem 2rem;
	}

	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(180deg, rgba(0, 0, 0, 30%) 0%, rgba(0, 0, 0, 65%) 100%);
	}

	.container {
		position: relative;
		z-index: 2;
	}
}

.cta-banner-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	object-position: center;
}

.cta-banner-heading {
	color: #fff;
	font-size: 2.625rem;
	font-weight: 700;
	margin-bottom: 1.25rem;
}

.cta-banner-content {
	color: #fff;
	font-size: 1.0625rem;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1.75rem;
	max-width: 520px;
}
