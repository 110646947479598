.hp-tip {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: start;
}

.hp-tip-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 25%;
	padding: 0 1rem;
	gap: 1rem;

	@media all and (max-width: $breakpoint-tablet) {
		width: 50%;
		margin-bottom: 2rem;
	}
}

.hp-tip-item-title {
	color: $theme-color-primary;
	font-size: 1.375rem;
	font-weight: 700;
	line-height: 1.18;
	text-align: center;

	@media all and (max-width: $breakpoint-tablet) {
		font-size: 1.125rem;
	}
}
