form.account-form {
	flex: 1;

	fieldset {
		border: none;
		padding: 1rem 2rem;
		margin-bottom: 1rem;
		background: #f1f1f1;
		border-radius: 1rem;
		display: flex;
		flex-flow: row wrap;
		gap: 0 1rem;

		&:nth-child(2) {
			opacity: .5;
		}

		&:last-child {
			margin-bottom: 0;
			background: transparent;
			width: 100%;

			.mb-3:nth-child(2) {
				width: 100%;
			}

			.mb-3:nth-child(3) {
				text-align: right;
				width: 100%;
			}
		}

		legend {
			font-size: 1.5rem;
			font-weight: 900;
			grid-column: span 6 / span 6;
		}

		.mb-3:nth-child(2) {
			width: calc(33.3333% - .8rem);
		}

		.mb-3:nth-child(3) {
			width: calc(33.3333% - .8rem);
		}

		.mb-3:nth-child(4) {
			width: calc(33.3333% - .8rem);
		}

		.mb-3:nth-child(5) {
			width: calc(50% - .8rem);
		}

		.mb-3:nth-child(6) {
			width: calc(50% - .8rem);
		}
	}
}
