.app-search {
	position: relative;
	width: 100%;
	max-width: 950px;
	margin: 0 auto;

	.app-search-input {
		width: 100%;
		height: 5rem;
		border-radius: 2.5rem;
		border: 0;
		padding-left: 2.5rem;
		padding-right: 5rem;
		font-size: 1.375rem;
		appearance: none;
		background: #f1f1f1;

		&:focus {
			outline: none;
			color: $theme-color-cyan;
		}
	}

	.app-search-button {
		position: absolute;
		top: 50%;
		right: .5rem;
		width: 4rem;
		height: 4rem;
		border-radius: 2rem;
		background: $theme-color-primary;
		//border: none;
		transform: translateY(-50%);
		overflow: hidden;
		transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
		border: 0;

		span {
			display: inline-block;
			text-indent: -9999px;
		}

		&:hover {
			transform: translateY(-50%) rotate(160deg);
		}
	}

	@media all and (max-width: 768px) {

		.app-search-input {
			font-size: 1.125rem;
			height: 4rem;
			padding-left: 2rem;
			padding-right: 4rem;
			border-radius: 2rem;
		}

		.app-search-button {
			width: 3.5rem;
			height: 3.5rem;
			border-radius: 1.75rem;
			right: .25rem;

			svg {
				width: 2rem;
				height: 2rem;
			}
		}
	}
}

#search-eye {
	transform-origin: center;
	animation: look-around 5s infinite;
}

#search-eye-bulb {
	transform-origin: center;
	animation: look-blink 3s infinite;
}

@keyframes look-around {

	0%,
	15%,
	100% {
		transform: translate(1396px, 375px);
	}

	25% {
		transform: translate(1396px, 379px);
	}

	35% {
		transform: translate(1396px, 379px);
	}

	50% {
		transform: translate(1392px, 375px);
	}

	75% {
		transform: translate(1392px, 375px);
	}
}

@keyframes look-blink {

	0%,
	100% {
		fill: #fff;
	}

	48% {
		fill: #fff;
	}

	50% {
		fill: #000;
	}

	52% {
		fill: #fff;
	}
}
