.account-detail__card {
	display: flex;
	flex-flow: column nowrap;
	background: $theme-color-yellow;
	border-radius: 1.375rem;
	padding: 1.375rem;
	max-width: 390px;
	width: 100%;
}

.account-detail__subscription-term {
	font-size: 1.125rem;
	margin-top: 2rem;
	padding-left: 1rem;
}

.account-detail__subscription {
	border-radius: 2rem;
	padding: 1rem;
	width: 100%;
	border: 3px solid #f1f1f1;
	gap: 2rem;

	&:last-child {
		margin-bottom: 3rem;
	}
}

.account-detail__subscription-issuer-img {
	border-radius: 1rem;
	width: 100%;
	height: auto;
	object-fit: cover;
}
