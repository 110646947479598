.page-header {
	padding: 3.125rem;
	color: $theme-color-secondary;
}

.page-header-strip-bottom {
	border-bottom: 6px solid $theme-color-secondary;
}

.page-content {
	max-width: 820px;
	width: 100%;
	margin: 0 auto;
	padding: 4rem 0;
}

.page-stripe {
	background: $theme-color-yellow;
	padding-bottom: 10px;
	margin-bottom: 2rem;
}
