.banner {
	position: relative;
	background-color: $theme-color-secondary;
	padding: 4.5rem;
	color: white;
	z-index: 2;
	border-radius: 30px;

	p {
		margin: 0 auto;
		max-width: 700px;
	}

	.content-heading {
		color: #fff;
	}
}
