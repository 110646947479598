.product-detail-stripe {
	background-color: $theme-color-primary;
	width: 100%;
	height: 15px;
	margin-bottom: 3rem;
}

.product-detail-header {
	position: relative;
	padding-top: 5.5rem;
	color: $theme-color-primary;

	h1 {
		font-size: 3.375rem;
	}
}

.product-detail-description-heading {
	margin-bottom: 1rem;

	span {
		color: $theme-color-cyan;
	}
}

.product-gallery-image {

	img {
		width: 100%;
		height: auto;
		border-radius: 10px;
	}

	&__main {

		img {
			border-radius: 20px;
		}
	}
}

.product-detail-wrapper {
	padding-left: 5rem;

	@media (max-width: 1460px) {
		padding-left: 3rem;
	}

	@media (max-width: 1200px) {
		padding-left: 0;
	}
}

.product-detail-discount {
	color: $theme-color-cyan;
	font-size: 1.5rem;
}

.product-gallery {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin-top: 1rem;
	gap: .75rem;

	&.count-3 {
		justify-content: center;
	}

	.product-gallery-image {
		position: relative;

		img {
			width: 100%;
			height: auto;
			border-radius: 10px;
		}
	}
}

.product-gallery-image-plus {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 50%);
	color: #fff;
	font-size: 1.5rem;
	padding: .5rem 1rem;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
