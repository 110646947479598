.content-section {
	position: relative;
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.content-section-stripe {
	background-color: $theme-color-blue-light;
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

.content-header {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	margin-bottom: 3rem;
	color: #000;
}

.content-heading {
	font-size: 3.375rem;
	line-height: 1;
	margin: 0;
	text-align: center;

	@media all and (max-width: 1024px) {
		font-size: 3rem;
	}

	@media all and (max-width: 768px) {
		font-size: 2.5rem;
	}
}

.content-kicker {
	color: $theme-color-primary;
	text-transform: uppercase;
	font-family: $fontHeading;
	font-weight: 700;
	padding: .5rem 0;
	margin: 0;
}

.content-bg-grey {
	background-color: $theme-bg-light;
}

.text-highlight {
	background-image: url("../../images/highlight.svg");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	animation: highlight .7s ease-out;

	&.bg-pink {
		background-image: url("../../images/highlight_pink.svg");
	}
}

@keyframes highlight {

	0% {
		background-size: 0 100%;
	}

	100% {
		background-size: 100% 100%;
	}
}
