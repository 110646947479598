.accommodation-card {
	display: flex;
	flex-direction: column;
	background-color: white;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.accommodation-image {
	width: 100%;
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
	}
}

.accommodation-card-body {
	display: flex;
	flex-direction: column;
	padding: 1.875rem 2.5rem 0;
	height: auto;
	flex: 1;
}

.accommodation-card-footer {
	width: 100%;
	padding: 0 2.5rem 1.875rem;
}

.accommodation-name {
	font-size: 1rem;
	font-weight: 700;
	padding-bottom: 1rem;
	color: $theme-color-primary;
}

.accommodation-tag {
	position: absolute;
	top: .5rem;
	left: .5rem;
	color: #fff;
	background: #252525;
	display: block;
	margin-bottom: 0;
	text-align: center;
	padding: .25rem 1rem;
	border-radius: 4px;
	font-size: .875rem;

	&__adults {
		background: $red;
		color: white;
	}

	&__children {
		background: $green;
		color: white;
	}
}

.accommodation-discount {
	position: absolute;
	top: .5rem;
	right: .5rem;
	color: #fff;
	background: $theme-color-secondary;
	display: block;
	margin-bottom: 0;
	text-align: center;
	padding: .25rem 1rem;
	border-radius: 4px;
	font-size: .875rem;
}

.accommodation-price-current {
	color: $theme-color-secondary;
	text-align: right;
	margin-bottom: 0;

	strong {
		color: $theme-color-primary;
		font-size: 1.5rem;
		font-weight: 700;
		display: block;
	}
}

.accommodation-price-before {
	color: $theme-color-secondary;
	text-align: right;
	margin-bottom: 0;
	text-decoration: line-through;
	line-height: 1;
}

.accommodation-card-price {
	padding: 0 1rem;
}
