.login-page {
	min-height: 100vh;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.login-promo-block {
	display: flex;
	flex-direction: column;
	background-color: $theme-color-yellow;
	background-image: url("../../images/bg-login.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 11.25rem;
	height: 100%;

	h2 {
		color: #1d1d1d;
		font-size: 2.625rem;
		font-weight: 700;
		line-height: 114.286%;
		margin-bottom: 2.5rem;
		margin-left: auto;
		margin-right: auto;
		max-width: 360px;
		width: 100%;
		text-align: center;
	}

	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.login-page-col {
	width: 50%;

	@media all and (max-width: 1200px) {
		width: calc(100% - 380px);
	}

	@media all and (max-width: 768px) {
		width: 100%;
	}
}

.login-page-col-left {
	width: 50%;
	flex: 0 0 50%;

	@media all and (max-width: 1200px) {
		width: 380px;
		flex: 0 0 380px;
	}

	@media all and (max-width: 768px) {
		display: none;
	}
}
