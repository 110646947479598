#product-showcase {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
}

.product-showcase-col {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.product-showcase-wrapper {
	position: relative;
	width: 100%;
	height: 300px;
}

.product-showcase-item {
	position: absolute;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 300px;
	transition: all .5s ease-in-out;

	&.current-item {
		opacity: 1;
		z-index: 1;
	}

	&.new-item {
		z-index: 0;
		opacity: 0;
	}
}

.product-showcase-item-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.product-showcase-item-content {
	position: absolute;
	display: flex;
	justify-content: end;
	flex-direction: column;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1.5rem 2rem;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0%) 1.56%, rgba(0, 0, 0, 50%) 64.06%);
	color: #fff;
}

.product-showcase-item-heading {
	font-size: 1.375rem;
	font-weight: 700;
	margin-bottom: 0;
}

.product-showcase-item-perex {
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: break-word;
}

.product-showcase-item-sale {
	position: absolute;
	top: 0;
	right: 2rem;
	font-size: 1.0625rem;
	font-weight: 700;
	color: #fff;
	//background: url("../../images/icon-sale-tag.svg") no-repeat;
	background-size: 100% 100%;
	padding: 1.5rem 1rem 1.75rem;
	line-height: 1;
	white-space: nowrap;
}
