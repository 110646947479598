.account-layout {
	display: grid;
	grid-template-columns: 390px 1fr;
	grid-gap: 4rem;
	margin: 4rem 0;

	@media all and (max-width: 1024px) {
		grid-template-columns: 1fr;
	}
}
