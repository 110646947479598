#main-menu {
	display: flex;

	&:not(.loggedin) {
		@media all and (max-width: $breakpoint-menu) {
			display: none;
		}
	}
}

.main-menu-list {
	list-style: none;
	margin: 0;
	display: flex;
	gap: 2.5rem;
}

.main-menu-link {
	text-decoration: none;
	color: #000;
	font-weight: 400;
	font-size: 1rem;
	padding: .5rem 0;
	border-bottom: 4px solid transparent;
	line-height: 140%;
	transition: text-shadow .2s ease-in-out;

	&.active {
		color: $theme-color-cyan;
	}

	&:hover,
	&:focus,
	&:focus-visible {
		color: #000;
	}

	&:focus-visible {
		outline: none;
		background-color: #000;
		color: #fff;
		border-radius: 5px;
	}
}

.user-menu {
	display: flex;
	margin-left: 2.5rem;
}

.user-menu-button {
	position: relative;
	display: inline-block;
	color: #000;
	font-size: 1rem;
	text-decoration: none;
	line-height: 1.38em;
	background-color: transparent;
	outline: none;
	transition: all .2s ease-in-out;
	overflow: hidden;
	text-align: center;
	font-weight: 400;
}

.main-menu__dropdown {
	position: relative;
	list-style: none;
	margin: 0;
	display: flex;
	margin-left: 2.5rem;
}

.main-menu__dropdown-button {
	position: relative;
	display: inline-block;
	color: $theme-color-cyan;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
	line-height: 1.38em;
	background-color: transparent;
	outline: none;
	transition: all .2s ease-in-out;
	overflow: hidden;
	background-image: url("../../images/icon-language.svg");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 1rem auto;
	padding-left: 1.375rem;
	padding-right: 1rem;
	border: 0;
	// add caret

	&:after {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #000;
	}
}

.main-menu__dropdown-list {
	list-style: none;
	position: absolute;
	top: 100%;
	right: 0;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	padding: .5rem;
	box-shadow: 0 .25rem .5rem rgba(0, 0, 0, 30%);
	margin-top: 1rem;
	display: none;

	&.is-active {
		display: block;
	}
}

.main-menu__dropdown-link {
	color: #000;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 400;
	display: block;

	&.active {
		color: $theme-color-cyan;
	}
}
