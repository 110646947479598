.card {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	border: 3px solid #f1f1f1;
	background-color: #f1f1f1;

	&.onclick {
		cursor: pointer;
	}

	//.card-discount-tag {
	//	position: absolute;
	//	top: 1.375rem;
	//	left: -1rem;
	//	color: #000;
	//	border-top-right-radius: 20px;
	//	border-bottom-left-radius: 20px;
	//	font-weight: 900;
	//	font-size: 1.5rem;
	//	line-height: 1;
	//	text-align: right;
	//	background: url("../../images/discount-tag.svg") no-repeat center;
	//	background-size: 100% 100%;
	//	padding: 1rem 1.625rem;
	//
	//	small {
	//		font-size: .875rem;
	//	}
	//}
}

.card-header {
	display: flex;
	flex-direction: column;
	border-radius: 17px;
	background: white;
}

.card-header-content {
	padding: .5rem 1rem;

	p {
		margin-bottom: .5em;
	}
}

.card-header-image {
	position: relative;
	margin-left: -3px;
	margin-right: -3px;
	margin-top: -3px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 20px;
	}
}

.card-discount-tag-wrapper {
	position: absolute;
	bottom: 0;
	left: 20px;
	padding-left: 20px;
	padding-right: 20px;

	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: transparent;
		border-bottom-right-radius: 20px;
		height: 40px;
		width: 20px;
		box-shadow: 0 20px 0 0 #fff;
		z-index: 1;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: transparent;
		border-bottom-left-radius: 20px;
		height: 40px;
		width: 20px;
		box-shadow: 0 20px 0 0 #fff;
		z-index: 1;
	}
}

.card-discount-tag {
	background-color: #fff;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 7px;
	min-height: 40px;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $theme-color-primary;
		border-radius: 17px;
		font-size: 1.25rem;
		font-weight: 900;
		line-height: 1.25;
		padding: .3125rem 1rem;
		color: $theme-color-text;
	}

	&.disabled {

		div {
			background-color: #f1f1f1;
		}
	}
}

.card-body {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	height: auto;
	flex: 1 1 auto;
	background: white;
	border-radius: 17px;
	margin-top: 3px;
}

.card-footer {
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem;
	background: white;
	border-radius: 17px;
	margin-top: 3px;
}

.card-title {
	font-size: 1.25rem;
	font-weight: 700;
	color: $theme-color-text;
	text-decoration: none;
}
