.button-go-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 3.125rem;
	padding-right: 1.5rem;
	line-height: 1;
	background-image: url("../../images/icon-go-back-cyan.svg");
	background-repeat: no-repeat;
	background-position: left 1.5rem center;
	background-size: 1.125rem auto;
	color: $theme-color-cyan;
	background-color: #fff;
	text-decoration: none;
	transition: all .2s ease-in-out;
	border: 3px solid $theme-color-cyan;
	text-transform: uppercase;
	border-radius: 25px;
	height: 50px;
	outline: 0;
	font-weight: 700;

	&:hover {
		color: #fff;
		background-color: $theme-color-cyan;
		background-image: url("../../images/icon-go-back.svg");
		background-position: left 1rem top 70%;
	}
}

.button {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	line-height: 1.5;
	background-color: $theme-color-secondary;
	border: 4px solid $theme-color-secondary;
	outline: none;
	padding: 1rem 2rem;
	transition: all .2s ease-in-out;
	overflow: hidden;
	text-align: center;
	white-space: nowrap;
	border-radius: 30px;
	min-width: 300px;
	text-transform: uppercase;

	&:hover {
		color: $theme-color-secondary;
		background-color: #fff;
		box-shadow: 0 .25rem .5rem rgba(0, 0, 0, 30%);
	}

	&.disabled,
	&:disabled {
		color: $theme-color-primary;
		background: white;
		border: 1px solid $theme-color-primary;
	}

	&.arrow-right {
		background-image: url("../../images/icon-arrow-right.svg");
		background-repeat: no-repeat;
		background-position: right 1rem center;
		background-size: 20px auto;
		padding-right: 3rem;
		padding-left: 3rem;

		&__hover {
			background-position: right 1rem center;

			&:hover {
				background-position: right .5rem center;
				background-image: url("../../images/icon-arrow-right-blue.svg");
			}
		}
	}

	&.icon-arrow-down {
		background-image: url("../../images/button-icon-arrow-down-white.svg");
		background-repeat: no-repeat;
		background-position: right 1.1875rem center;
		background-size: 20px auto;
	}

	&--small {
		padding: .5rem 1rem;
		font-size: .875rem;
	}

	&--white {
		color: $theme-color-secondary;
		background-color: #fff;
		border: 4px solid #fff;

		&:hover {
			color: #fff;
			background-color: $theme-color-secondary;
		}
	}
}
