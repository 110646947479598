.contact-block {
	list-style: none;
	margin: 0;
	padding-left: 40px;
	position: relative;
}

.contact-item {
	position: relative;
	min-height: 30px;
	margin-bottom: .25rem;

	a {
		color: #000;
		text-decoration: none;
	}

	&:before {
		display: inline-block;
		width: 30px;
		height: 30px;
		content: "";
		background-size: 30px 30px;
		background-repeat: no-repeat;
		margin-left: -40px;
		position: absolute;
		left: 0;
		top: 0;
	}

	&__location:before {
		background-image: url("../../images/icon-product-location.svg");
	}

	&__phone:before {
		background-image: url("../../images/icon-product-phone.svg");
	}

	&__email:before {
		background-image: url("../../images/icon-product-email.svg");
	}

	&__website:before {
		background-image: url("../../images/icon-product-website.svg");
	}
}
