.cta-banner-page {
	background-color: #f4f5ff;
	padding: 5rem 0;

	@media all and (max-width: $breakpoint-tablet) {
		padding: 3.5rem 0;
	}
}

.cta-banner-page-heading {
	color: #fff;
	font-size: 1.875rem;
	font-weight: 700;
	margin-bottom: 1.25rem;
	line-height: 120%;
}

.cta-banner-page-perex {
	color: #fff;
	font-size: 1.0625rem;
	margin-bottom: 1.75rem;
	line-height: 1.47;
}

.cta-banner-page-content {
	color: #fff;
	background: $theme-color-secondary;
	padding: 2.5rem 2rem;
	text-align: center;
}
