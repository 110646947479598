#faq {
	padding: 4rem 0;
}

.faq-item {
	display: flex;
	flex-wrap: wrap;
	background: #f9f8f3;
	border-radius: 15px;

	& + .faq-item {
		margin-top: 1rem;
	}
}

.faq-question {
	position: relative;
	flex: 1 1 100%;
	background: $theme-color-primary;
	color: #000;
	border: 0;
	outline: 0;
	text-align: left;
	display: inline-block;
	padding: 1rem 4rem 1rem 1.5rem;
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 133.33%;
	border-radius: 15px;

	// span element looks like a plus sign. If is open, it will rotate 180 degrees and look like a minus sign.

	span {
		position: absolute;
		right: 1.5rem;
		top: 50%;
		transform: translateY(-50%);
		transition: all .2s ease-in-out;

		&:before {
			content: "";
			position: absolute;
			width: 2px;
			height: 1rem;
			background: #000;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all .2s ease-in-out;
		}

		&:after {
			content: "";
			position: absolute;
			width: 1rem;
			height: 2px;
			background: #000;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all .2s ease-in-out;
		}
	}

	&.open {

		span {
			transform: translateY(-50%) rotate(0deg);

			&:before {
				transform: translate(-50%, -50%) rotate(90deg);
			}

			&:after {
				transform: translate(-50%, -50%) rotate(180deg);
				opacity: 0;
			}
		}
	}
}

.faq-answer {
	flex: 1 1 100%;
	max-height: 0;
	transition: all .2s ease-in-out;
	overflow: hidden;
	opacity: 0;
	padding: 0 1.5rem;
	z-index: -10;

	&.open {
		opacity: 1;
		z-index: 10;
		padding: 1rem 1.5rem;
	}

	p:last-child {
		margin-bottom: 0;
	}
}
