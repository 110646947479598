.hp-steps {
	display: flex;
	flex-wrap: wrap;
	align-items: start;
	gap: 3rem;

	.hp-steps-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		position: relative;
		flex: 1 1 calc(25% - 1.5rem);

		@media all and (max-width: $breakpoint-tablet) {
			flex: 1 1 calc(50% - 1rem);
			margin-bottom: 1.185rem;

			//	&:nth-child(3) {
			//		margin-bottom: 4rem;
			//	}
			//
			//	&.mobile-last {
			//		order: 3;
			//
			//		.hp-steps-icon:before {
			//			content: "";
			//			display: block;
			//			position: absolute;
			//			top: 0;
			//			left: 50%;
			//			width: 28px;
			//			height: 28px;
			//			background-image: url("../../images/icon-step-arrow-down-mobile.svg");
			//			background-size: auto 28px;
			//			background-repeat: no-repeat;
			//			background-position: center;
			//			transform: translate(-50%, -2.875rem);
			//		}
			//	}
		}
	}

	.hp-steps-icon {
		display: block;
		position: relative;
		width: 5rem;
		height: 5rem;
		background-position: center;
		background-size: 5rem auto;
		background-repeat: no-repeat;
		transition: all .2s ease-in-out;
		margin-bottom: 1.125rem;

		span {
			font-size: 3rem;
			font-weight: 700;
			color: $theme-color-secondary;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&__1 {
			background-image: url("../../images/icon-card.svg");
		}

		&__2 {
			background-image: url("../../images/icon-accommodation.svg");
		}

		&__3 {
			background-image: url("../../images/icon-discount.svg");
		}
	}

	.hp-steps-item-title {
		color: #000;
		font-size: 2.25rem;
		font-weight: 900;
		line-height: 1.18;
		text-align: center;
		transition: all .2s ease-in-out;
		margin-bottom: .75rem;

		@media all and (max-width: $breakpoint-tablet) {
			font-size: 1.5rem;
		}
	}

	.hp-steps-item-text {
		color: #4d4d4d;
		font-size: 1.25rem;
		line-height: 1.28;
		text-align: center;
		transition: all .2s ease-in-out;

		@media all and (max-width: $breakpoint-tablet) {
			font-size: 1rem;
		}
	}
}
