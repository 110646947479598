.netteFormsModal {
	border-radius: 15px;
	border: 2px solid $theme-color-secondary !important;
	color: $theme-color-heading;
	font-weight: 700;

	button {
		position: relative;
		display: inline-flex;
		align-items: center;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		line-height: 1;
		color: #fff;
		background-color: $theme-color-secondary;
		text-decoration: none;
		transition: all .2s ease-in-out;
		border: 2px solid $theme-color-secondary;
		text-transform: uppercase;
		border-radius: 20px;
		height: 40px;
	}
}

dialog:-internal-dialog-in-top-layer::backdrop {
	backdrop-filter: blur(5px);
	background-color: rgba(255, 255, 255, 50%);
}
