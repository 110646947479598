.notification {
	display: flex;
	flex-wrap: nowrap;
	z-index: 100;
	padding: 1rem 0;
	position: relative;
	background: #f1f1f1;

	&:last-child {
		margin-bottom: 2rem;
	}

	p:last-child {
		margin-bottom: 0;
	}

	&__icon {
		display: flex;
		content: "";
		width: 3rem;
		height: 2rem;
	}

	&__wrapper {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		width: 100%;
	}

	&__heading {
		font-size: 1.5rem;
		margin: 0;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		gap: .5rem 3rem;
	}

	// Danger style

	&--danger {
		background: $theme-color-cyan;
		color: #fff;

		.notification__heading {
			color: #fff;
		}

		.notification__icon {
			background: url("../../images/icon-status-danger.svg") no-repeat center;
			background-size: 2rem auto;
		}
	}
}

.notification-detail {
	position: relative;
	display: flex;
	flex-flow: column wrap;
	padding: 1.25rem 3rem;
	background: #f1f1f1;
	border-radius: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;

	// make top triangle

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 1rem;
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-bottom: 1rem solid #f1f1f1;
		transform: translateY(-100%);
	}
}
