.flash-message-modal {
	z-index: 9999;
}

.flash-message-modal-popup {
	border-radius: 0;
}

.flash-message-modal-title {
	color: #000;
}

.flash-message-modal-message {
	color: #000 !important;
}

.flash-message-modal-button-confirm {
	background-color: $theme-color-secondary !important;
	color: #fff;
	border-radius: 0 !important;
	padding: .9375rem 1.6875rem;

	&:focus-visible,
	&:focus {
		box-shadow: none !important;
	}

	&:hover {
		background-color: $theme-color-secondary !important;
		background-image: none !important;
	}
}
