.howto-header {
	margin-bottom: 1.125rem;
	color: $theme-color-primary;
}

.howto-heading {
	font-size: 2.625rem;
}

.howto-h3-heading {
	font-size: 1.875rem;
}

.howto-text {
	max-width: 600px;
	margin: 0 auto;
	font-size: 1.0625rem;
}

.howto-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 2rem;
	margin-left: -.625rem;
	margin-right: -.625rem;
}

.howto-card-col {
	flex: 0 0 33.3333%;
	max-width: 33.3333%;
	margin-bottom: 1.25rem;
	padding-left: .625rem;
	padding-right: .625rem;

	@media all and (max-width: $breakpoint-tablet) {
		flex: 0 0 50%;
		max-width: 50%;
	}

	@media all and (max-width: $breakpoint-mobile) {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.howto-card {
	width: 100%;
	height: 100%;
	padding: 2rem;
	background: $theme-color-secondary;
	color: #fff;

	img {
		margin-bottom: 1.5rem;
	}

	.howto-card-title {
		font-size: 1.375rem;
		margin-bottom: .75rem;
		font-weight: 700;
	}

	p {
		font-size: 1.0625rem;
		margin-bottom: 0;
	}
}

.howto-content-section-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.75rem;
	margin-right: -1.75rem;
}

.howto-content-section-col {
	padding-left: 1.75rem;
	padding-right: 1.75rem;
	flex: 0 0 50%;
	max-width: 50%;
	margin-bottom: 2rem;

	@media all and (max-width: $breakpoint-tablet) {
		flex: 0 0 100%;
		max-width: 100%;

		&:last-child {
			order: -1;
		}
	}
}

.howto-content-section-img {
	width: 100%;
	height: auto;
}
