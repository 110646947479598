.categories {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: start;

	.category {
		display: flex;
		flex: 1 1 25%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 25%;
		padding: 0 1rem;
		gap: 1rem;
		text-decoration: none;
		cursor: pointer;

		@media all and (max-width: $breakpoint-tablet) {
			flex: 1 1 50%;
			width: 50%;
			margin-bottom: 2rem;
		}

		&.active,
		&:hover {

			.category-icon {
				border-color: $theme-color-primary;

				//&__culture {
				//	background-image: url("../../images/icon-culture-red.svg");
				//}
				//
				//&__sport {
				//	background-image: url("../../images/icon-sport-red.svg");
				//}
				//
				//&__experiences {
				//	background-image: url("../../images/icon-experiences-red.svg");
				//}
				//
				//&__gastro {
				//	background-image: url("../../images/icon-gastro-red.svg");
				//}
			}

			.category-title {
				color: $theme-color-primary;
			}
		}
	}

	.category-icon {
		display: block;
		width: 7.625rem;
		height: 7.625rem;
		border: 6px solid $theme-color-secondary;
		background-position: center;
		background-size: 4.5rem auto;
		background-repeat: no-repeat;
		transition: all .2s ease-in-out;

		//&__culture {
		//	background-image: url("../../images/icon-culture.svg");
		//}
		//
		//&__sport {
		//	background-image: url("../../images/icon-sport.svg");
		//}
		//
		//&__experiences {
		//	background-image: url("../../images/icon-experiences.svg");
		//}
		//
		//&__gastro {
		//	background-image: url("../../images/icon-gastro.svg");
		//}
	}

	.category-title {
		color: $theme-color-secondary;
		font-size: 1.375rem;
		font-weight: 700;
		line-height: 1.18;
		text-align: center;
		transition: all .2s ease-in-out;

		@media all and (max-width: $breakpoint-tablet) {
			font-size: 1.125rem;
		}
	}
}
