.form {

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="url"],
	input[type="email"] {
		width: 100%;
		height: 2.75rem;
		border: 3px solid #f1f1f1;
		border-radius: 1.375rem;
		caret-color: $theme-color-cyan;
		padding-left: 1.25rem;
		background-color: #fff;

		&:focus-visible,
		&:focus {
			outline: 0;
			border: 3px solid $theme-color-secondary;
		}
	}

	textarea {
		caret-color: $theme-color-cyan;

		&:focus-visible,
		&:focus {
			outline: 0;
			border: 3px solid $theme-color-secondary;
		}
	}

	select {
		appearance: none;
		background-color: #fff;
		background-image: url("../../images/icon-arrow-down.svg");
		background-repeat: no-repeat;
		background-position: right 1rem center;
		background-size: 12px 12px;
		width: 100%;
		height: 2.75rem;
		border: 3px solid #f1f1f1;
		border-radius: 1.375rem;
		padding-left: 1.25rem;

		&:focus-visible,
		&:focus {
			outline: 0;
			border: 3px solid $theme-color-secondary;
		}
	}

	label {
		color: $theme-color-text;
		padding-left: 1.25rem;
		font-weight: 700;
	}

	.form-check {
		position: relative;
		//padding-left: 0;

		input {
			width: 0;
			height: 0;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;

			&:checked ~ .form-check-label:before {
				background-image: url("../../images/icon-form-checked.svg");
				background-size: 10px 10px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		.form-check-label {
			display: inline-flex;
			align-items: center;
			width: 100%;
			padding-left: 0;

			&:before {
				display: inline-block;
				content: "";
				width: 1rem;
				height: 1rem;
				border: 2px solid $theme-color-text;
				margin-right: .675rem;
				transition: all .2s ease-in-out;
				background-image: none;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	.activation-code-input {
		height: 44px;
		border-radius: 22px;
		border: 2px solid #f1f1f1;
		//border-bottom: 0;
		padding-left: 1.25rem;
		padding-right: .5rem;
		padding-bottom: .25rem;
		text-transform: uppercase;
	}
}

.code-login-form {

	.code-divider {
		font-size: 2.5rem;
		margin: 0 .5rem;
	}

	input[type="text"] {
		width: 100%;
		border: 0;
		padding: .5rem;
		background: $theme-color-blue-light;
		border-radius: 5px;
		font-size: 2rem;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;

		&:focus {
			outline: 2px solid $theme-color-secondary;
		}
	}
}

.form-custom-radio {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 2.5rem;
	gap: .5rem;

	@media all and (max-width: 1400px) {

		label {
			width: 100%;
		}
	}

	span {
		width: 100%;
		cursor: pointer;
	}

	input {
		display: none;

		&:checked + span {
			background-color: $theme-color-secondary;
			color: #fff;
		}
	}
}

.input-error {
	color: $theme-color-cyan;

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="url"],
	input[type="email"] {
		border: 3px solid $theme-color-cyan;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
		padding-right: calc(1.5em + .75rem);
		background-repeat: no-repeat;
		background-position: right calc(.375em + .1875rem) center;
		background-size: calc(.75em + .375rem) calc(.75em + .375rem);
	}

	span {
		padding: 0 1.25rem;
	}
}

.input-valid {

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="url"],
	input[type="email"] {
		border: 3px solid $theme-color-green;
		padding-right: calc(1.5em + .75rem);
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right calc(.375em + .1875rem) center;
		background-size: calc(.75em + .375rem) calc(.75em + .375rem);
	}
}

.form-text {
	display: inline-flex;
	font-size: 1rem;
	padding: .125rem 1.25rem;
}

.activation-form__title {
	font-size: 1.25rem;
	font-weight: 900;
	color: $theme-color-text;
}

.activation-form__text {
	font-size: .875rem;
	margin-bottom: .5rem;
}

.activation-form__wrapper {
	display: none;
}
