.cooperation-heading {
	font-size: 2.625rem;
	font-weight: 700;
	margin-bottom: 1.25rem;
	line-height: 114.286%;
}

.cooperation-text {
	font-size: 1.0625rem;
	font-weight: 400;
	line-height: 147.059%;
	margin-bottom: 2.5rem;
}

.cooperation-success-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	@media all and (max-width: 1024px) {

		.col-6 {
			width: 100%;
		}
	}
}

.cooperation-success-heading {
	font-size: 2.625rem;
	font-weight: 700;
	line-height: 3rem;
	margin-bottom: .5rem;

	strong {
		color: $theme-color-primary;
	}
}

.cooperation-success-text {
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 147.059%;
	margin-bottom: 1.5rem;
	color: $theme-color-primary;
}

.cooperation-success-icon {
	margin-bottom: 2.5rem;
}

.cooperation-form-row {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
	margin-left: -6px;
	margin-right: -6px;
}

.cooperation-form-col {
	padding-left: 6px;
	padding-right: 6px;
	width: 50%;
	flex: 0 0 50%;

	@media all and (max-width: 1024px) {
		width: 100%;
		flex: 0 0 100%;
	}
}

.cooperation-type {
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 1.5rem;
	width: 100%;
	justify-content: center;
	align-items: center;

	@media all and (max-width: 1024px) {
		flex-direction: column;
	}
}

.cooperation-type-select {
	padding: 2rem;
	background: $theme-bg-light;
	cursor: pointer;
	align-self: stretch;
	border-radius: 15px;

	&:hover {
		background: $theme-color-primary;

		.cooperation-type-select-title {
			color: $theme-color-secondary;
		}
	}

	&.active {
		background: $theme-color-text;
		color: #fff;

		.cooperation-type-select-title {
			color: $theme-color-primary;
		}
	}
}

.cooperation-type-select-divider {
	padding: 2rem;
	font-size: 2rem;
	font-weight: 700;
	text-transform: uppercase;
}

.cooperation-type-select-title {
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1rem;
	display: block;
}
