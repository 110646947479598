#masthead {
	display: flex;
	align-items: center;
	color: #000;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: all .3s ease-in-out;
	z-index: 2000;
	padding: 1rem 0;
	height: 90px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 10%);

	@media all and (max-width: 520px) {

		.user-menu-button {
			display: none;
		}
	}

	&.hidden {
		transform: translateY(-100%);
	}

	&.smaller {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 10%);
	}

	//&.homepage {
	//	background: transparent;
	//
	//	.main-logo-link {
	//		background-image: url("../../images/logo-bw.svg");
	//	}
	//
	//	&.smaller {
	//		background: #fff;
	//
	//		.main-logo-link {
	//			background-image: url("../../images/logo.svg");
	//		}
	//	}
	//}
}

#masthead-divider {
	height: 90px;

	//&.homepage {
	//	height: 0;
	//}

	//	@media all and (max-width: $breakpoint-menu) {
	//		height: 80px;
	//}
}

.header-wrapper {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;

	&.scrolled {
		height: 70px;
	}
}

.main-logo-link {
	display: flex;
	margin-right: auto;
	overflow: hidden;
	text-indent: -9999px;
	background-image: url("../../images/logo.svg");
	width: 165px;
	height: 50px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 165px 50px;

	&:focus-visible {
		outline: 2px solid black;
		color: white;
		outline-offset: 10px;
	}

	@media all and (max-width: $breakpoint-menu) {

		img {
			width: 130px;
			height: auto;
		}
	}
}

.hamburger-button {
	height: 50px;
	position: relative;
	cursor: pointer;
	display: none;
	z-index: 1100;
	padding-left: 36px;

	span {
		font-weight: 700;
	}

	@media all and (max-width: $breakpoint-menu) {
		display: flex;
		align-items: center;
	}

	.hamburger-button-wrapper {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
		width: 16px;
		height: 12px;
	}

	.line {
		width: 16px;
		height: 2px;
		background-color: #fff;
		position: absolute;
		right: 0;
		transform: rotate(0deg);
		transition: all .3s ease-in-out;

		&.line-1 {
			top: 0;
		}

		&.line-2 {
			top: 5px;
		}

		&.line-3 {
			bottom: 0;
		}
	}

	&:hover {

		.line {

			&.line-1 {
				width: 50%;
			}

			&.line-3 {
				width: 75%;
			}
		}
	}

	&.active {

		.line {

			&.line-1 {
				width: 100%;
				transform: rotate(-135deg);
				top: 5px;
			}

			&.line-2 {
				width: 0;
				right: 50%;
			}

			&.line-3 {
				width: 100%;
				transform: rotate(135deg);
				bottom: 5px;
			}
		}
	}
}

#mobile-main-menu {
	background: #fff;
	width: 100%;
	position: fixed;
	right: 0;
	top: 80px;
	transition: all .3s ease-in-out;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	padding: 2rem 0 3.5rem;
	z-index: -1;

	&.active {
		display: block;
		max-height: 1000px;
		opacity: 1;
		z-index: 1000;

		@media all and (min-width: $breakpoint-menu + 1) {
			display: none;
			opacity: 0;
			max-height: 0;
		}
	}
}

.mobile-menu-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0;
}

.mobile-menu-link {
	margin-bottom: 1.5rem;
	font-weight: 700;
	line-height: 1.5;
	color: #000;
	text-decoration: none;
	padding: .5rem 0;
	display: block;

	&.active {
		border-bottom: 4px solid $theme-color-cyan;
	}
}
