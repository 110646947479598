.app-filters {
	background: $theme-color-blue-light;
	padding: 3.5rem 0 1rem;

	.filters-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 3.5rem;

		@media all and (max-width: 768px) {
			padding: 0;
		}
	}
}

.filter-dropdown {
	position: relative;
	display: inline-block;
}

.filter-dropdown-content {
	display: block;
	position: absolute;
	right: 0;
	background-color: #f9f9f9;
	padding: .5rem 0;
	z-index: 1;
	list-style: none;
	margin: 0;
	min-width: 280px;
	max-height: 0;
	overflow: hidden;
	transition: max-height .5s ease-out;
	visibility: hidden;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 10%);

	&.left {
		right: auto;
		left: 0;
	}

	&.open {
		visibility: visible;
		max-height: 1000px;
	}

	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		font-weight: 700;
		font-size: 1.125rem;
		margin: 0;
		padding: .75rem 1.25rem;
		white-space: nowrap;
		cursor: pointer;

		svg {
			fill: $theme-color-secondary;
		}

		&:hover {
			background-color: $theme-color-blue-light;
		}

		&.active {
			color: $theme-color-primary;

			svg {
				fill: $theme-color-primary;
			}
		}

		&.sorting-desc svg {
			transform: rotate(180deg);
		}
	}
}

.filter-dropdown-button {
	position: relative;
	display: inline-block;
	color: $theme-color-secondary;
	font-size: 1.0625rem;
	text-decoration: none;
	line-height: 1.38em;
	background-color: transparent;
	border: 3px solid $theme-color-secondary;
	outline: none;
	padding: .6875rem 1.0625rem;
	transition: all .2s ease-in-out;
	overflow: hidden;
	font-weight: 700;
	text-align: center;
	text-transform: lowercase;

	&:after {
		display: inline-block;
		content: "";
		width: 16px;
		height: 11px;
		background: url("../../images/icon-dropdown-blue.svg");
		margin-left: 1rem;
		transition: transform .1s ease-in-out;
	}

	&[aria-expanded="true"]:after {
		transform: rotate(180deg);
	}
}

.app-filter-button-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	color: $theme-color-secondary;
	font-weight: 700;

	.icon {
		width: 52px;
		height: 52px;
		//background: url("../../images/icon-filter.svg") no-repeat center;
		background-size: contain;
		margin-right: 1rem;
	}

	.close {
		display: none;
	}

	&.open {

		.open {
			display: none;
		}

		.close {
			display: block;
		}
	}

	@media all and (max-width: 480px) {

		.open,
		.close {
			display: none;
		}
	}
}
