.footer-app {
	background: #f9f8f3;
	padding: 3.5rem 0 0;
	border-top: 4px solid $theme-color-primary;
	color: #000;
}

.footer-app-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: stretch;
	align-items: flex-start;
}

.footer-app-col {
	padding: 0 2rem;

	& + .footer-app-col {
		border-left: 1px solid #fff;
	}

	@media all and (max-width: 1035px) {

		&:nth-child(2) {
			border-left: 0;
		}
	}

	@media all and (max-width: 768px) {
		border-left: 0;
		padding: 2rem 0;
		width: 100%;
		flex-wrap: wrap;
		text-align: center;

		& + .footer-app-col {
			border-left: 0;
			border-top: 1px solid #fff;
		}
	}

	&:first-child {
		padding-left: 0;
		max-width: calc(300px + 2rem);

		@media all and (max-width: 1035px) {
			width: 100%;
			max-width: 100%;
			text-align: center;
			padding-bottom: 2rem;
		}
	}

	&:last-child {
		padding-right: 0;

		//@media all and (max-width: 1035px) {
		//	padding: 2rem 0;
		//	width: 100%;
		//	border-top: 1px solid #fff;
		//	text-align: center;
		//	margin-top: 2rem;
		//	border-left: 0;
		//}
	}
}

.footer-logo-link {
	display: inline-block;
	margin-bottom: 1.5rem;
}

.footer-app-description {
	margin-bottom: 0;
	line-height: 150%;
}

.footer-title {
	font-size: 1.25rem;
	color: #000;
}

.footer-link {
	display: inline-block;
	line-height: 1.5;
	color: #000;

	&:hover,
	&.active {
		color: $theme-color-cyan;
	}
}

.footer-list {
	list-style: none;
	padding: 0;
	margin: 0;
	align-self: center;

	a {
		text-decoration: none;
	}
}

.footer-app-site {
	color: $theme-color-text;
	padding: 1rem 0;
	background: #fff;
	font-size: .75rem;
}

.footer-app-navbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 1.25rem 0;

	@media all and (max-width: $layout-width) {
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	@media all and (max-width: $breakpoint-tablet) {
		padding-top: 0;
	}

	ul {
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin: 0;
		list-style: none;

		@media all and (max-width: 1024px) {
			margin-bottom: 2rem;
		}

		@media all and (max-width: $breakpoint-mobile) {
			justify-content: center;
			align-items: center;
			flex-flow: wrap column;
		}

		li {
			padding: 1rem 1.5rem;
		}

		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			font-weight: 700;

			img {
				margin-right: .625rem;
			}

			&:hover {
				color: #000;
			}
		}
	}
}

.footer-project-text {
	font-size: .875rem;
	line-height: 1.25;
	color: #000;
}

.footer-logos {

	img {
		width: auto;
		max-width: 100%;
	}
}
