.container {
	max-width: $layout-width + 100;
	width: 100%;
	min-height: 1px;
	padding-left: 50px;
	padding-right: 50px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	@media all and (max-width: 1024px) {
		max-width: $layout-width + 50;
		padding-left: 25px;
		padding-right: 25px;
	}

	@media all and (orientation: landscape) and (max-width: 813px) {
		padding-left: 0;
		padding-right: 0;
		max-width: 90%;
	}

	&__small {
		max-width: $layout-width-small;
	}
}

.container-fluid {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-left: 50px;
	padding-right: 50px;
	margin-left: auto;
	margin-right: auto;
}
