.intro {
	position: relative;
	color: #fff;
	border-radius: 40px;
	overflow: hidden;

	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(180deg, rgba(0, 0, 0, 25%) 0%, rgba(0, 0, 0, 70%) 85.42%);
	}

	@media all and (max-width: 620px) {
		padding-bottom: 5rem;
	}
}

//.intro {
//	position: relative;
//	//background-color: $theme-color-primary;
//	background-image: url("../../images/hp-hero-bg.svg");
//	background-repeat: no-repeat;
//	background-position: left bottom;
//	background-size: cover;
//	color: #000;
//	padding-bottom: 12%;
//	padding-top: 8rem;
//	overflow: hidden;
//}

.intro-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	//transform: translateY(-50%);
	object-fit: cover;
	object-position: center;
}

.intro-wrapper {
	position: relative;
	z-index: 2;
	gap: 2rem;
}

.promo-block {
	display: flex;
	width: 60%;
	max-width: 60%;
	flex-direction: column;
	font-size: 1rem;
	position: relative;
	z-index: 5;
	padding-top: 5rem;
	padding-bottom: 5rem;

	@media all and (max-width: 768px) {
		width: 100%;
		max-width: 100%;
	}
}

.promo-block-heading {
	font-size: 3.375rem;
	font-family: $fontHeading;
	font-weight: 900;
	line-height: 109.259%;
	color: #fff;
	margin-bottom: 3rem;

	strong {
		color: $theme-color-yellow;
	}

	@media all and (max-width: 620px) {
		font-size: 2.625rem;
		line-height: 114.256%;
	}
}

.promo-block-text {
	font-size: 1.125rem;
	width: 100%;
	margin-bottom: 2.5rem;
	font-weight: 700;
	line-height: 133.333%;

	@media all and (max-width: 1024px) {
		font-size: 1.125rem;
	}
}

.promo-features {
	font-family: $fontHeading;
	font-size: 1.0625rem;
	line-height: 147.059%;
	list-style: none;
	padding: 0;

	li {
		padding-left: 2em;
		background-image: url("../../images/icon-check.svg");
		background-repeat: no-repeat;
		background-position: left center;
		background-size: 24px 24px;

		& + li {
			margin-top: .75em;
		}
	}

	&.smaller {
		font-size: 1rem;
	}
}

.promo-block-image {
	position: absolute;
	z-index: 5;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;

	img {
		position: absolute;
		top: 50%;
		left: -5%;
		width: 100%;
		max-width: 800px;
		height: auto;
		transform: translateY(-50%);
	}

	@media all and (max-width: $layout-width) {
		width: 40%;
	}

	@media all and (max-width: 768px) {
		display: none;
	}
}

.intro-button {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	z-index: 5;

	.button {
		border: 4px solid #fff;
	}

	@media all and (max-width: 768px) {
		text-align: center;
	}
}
