.account-login {
	padding: 5rem 7.5rem;
	height: 100%;

	@media all and (max-width: 1620px) {
		padding: 5rem 4rem;
	}

	@media all and (max-width: 768px) {
		padding: 5rem 2rem;
	}
}

.account-login-form-wrapper {
	//max-width: 365px;
	width: 100%;
}
